export enum RootGetters {
  USER_VALUE = 'USER_VALUE',
  CHECK_MOBILE_DEVICE = 'CHECK_MOBILE_DEVICE',
  CHECK_PHONE_DEVICE = 'CHECK_PHONE_DEVICE',
  VERSION = 'VERSION',
  GET_ROOT = 'GET_ROOT',
}

export enum RootMutations {
  SET_USER_VALUE = 'SET_USER_VALUE',
  SET_DEVICE = 'SET_DEVICE',
  SET_PHONE_DEVICE = 'SET_PHONE_DEVICE',
  SET_TOKEN = 'SET_TOKEN',
  SET_LAYOUT = 'SET_LAYOUT',
  SET_LOADING = 'SET_LOADING',
}

export enum RootActions {
  GET_USER_VALUE = 'GET_USER_VALUE',
  CHECK_MOBILE_DEVICE = 'CHECK_MOBILE_DEVICE',
  CHECK_PHONE_DEVICE = 'CHECK_PHONE_DEVICE',
  SET_TOKEN = 'SET_TOKEN',
  SET_LAYOUT = 'SET_LAYOUT',
  SET_LOADING = 'SET_LOADING',
}

// AUTH
export enum AuthGetters {
  AUTH_VALUE = 'AUTH_VALUE',
  IS_ADMIN = 'IS_ADMIN',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  FORGOT_EMAIL_DATA = 'FORGOT_EMAIL_DATA',
  NUMBER_UNREAD = 'NUMBER_UNREAD',
  GET_NOTIFICATIONS_COMMUNITY = 'GET_NOTIFICATIONS_COMMUNITY',
  NUMBER_UNREAD_COMMUNITY = 'NUMBER_UNREAD_COMMUNITY',
  GET_DETAIL_NOTIFICATIONS = 'GET_DETAIL_NOTIFICATIONS',
  GET_NOTIFICATION_FROM_ADMIN = 'GET_NOTIFICATION_FROM_ADMIN',
}

export enum AuthMutations {
  SET_AUTH_VALUE = 'SET_AUTH_VALUE',
  SET_FORGOT_EMAIL_DATA = 'SET_FORGOT_EMAIL_DATA',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_COMMUNITY = 'SET_NOTIFICATIONS_COMMUNITY',
  CHANGE_IS_READ = 'CHANGE_IS_READ',
  SET_NUMBER_UNREAD_YELLOOP = 'SET_NUMBER_UNREAD_YELLOOP',
  SET_NUMBER_UNREAD_COMMUNITY = 'SET_NUMBER_UNREAD_COMMUNITY',
  SET_DETAIL_NOTIFICATIONS = 'SET_DETAIL_NOTIFICATIONS',
  SET_NOTIFICATION_FROM_ADMIN = 'SET_NOTIFICATION_FROM_ADMIN',
}

export enum AuthActions {
  SET_AUTH_VALUE = 'SET_AUTH_VALUE',
  SET_FORGOT_EMAIL_DATA = 'SET_FORGOT_EMAIL_DATA',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_COMMUNITY = 'SET_NOTIFICATIONS_COMMUNITY',
  CHANGE_IS_READ = 'CHANGE_IS_READ',
  SET_NUMBER_UNREAD_YELLOOP = 'SET_NUMBER_UNREAD_YELLOOP',
  SET_NUMBER_UNREAD_COMMUNITY = 'SET_NUMBER_UNREAD_COMMUNITY',
  SET_DETAIL_NOTIFICATIONS = 'SET_DETAIL_NOTIFICATIONS',
  CLEAR_NUMBER_UNREAD_NOTIFICATIONS = 'CLEAR_NUMBER_UNREAD_NOTIFICATIONS',
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',
}
// REGISTER
export enum RegisterGetters {
  INVITE = 'INVITE',
  COMMUNITY_REQUIRED_COLUMNS = 'COMMUNITY_REQUIRED_COLUMNS',
  TERMS_SERVICE_YELLOOP = 'TERMS_SERVICE_YELLOOP',
  TERMS_SERVICE_COMMUNITY = 'TERMS_SERVICE_COMMUNITY',
  LIST_JOBS = 'LIST_JOBS',
  REGISTER_VALUE = 'REGISTER_VALUE',
  REGISTER_COMMUNITY_VALUE = 'REGISTER_COMMUNITY_VALUE',
  GET_INFO_ALUMNUS = 'GET_INFO_ALUMNUS',
  ALUMNUS_VALIDATE_DUPLICATE = 'ALUMNUS_VALIDATE_DUPLICATE',
  GET_VALIDATE_POST_ALUMNUS = 'GET_VALIDATE_POST_ALUMNUS',
  GET_VALIDATE_POST_COMMUNITY = 'GET_VALIDATE_POST_COMMUNITY',
}

export enum RegisterMutations {
  SET_INVITE = 'SET_INVITE',
  SET_COMMUNITY_REQUIRED_COLUMNS = 'SET_COMMUNITY_REQUIRED_COLUMNS',
  SET_TERMS_SERVICE_YELLOOP = 'SET_TERMS_SERVICE_YELLOOP',
  SET_TERMS_SERVICE_COMMUNITY = 'SET_TERMS_SERVICE_COMMUNITY',
  SET_LIST_JOBS = 'SET_LIST_JOBS',

  SET_REGISTER_VALUE = 'SET_REGISTER_VALUE',
  SET_REGISTER_COMMUNITY_VALUE = 'SET_REGISTER_COMMUNITY_VALUE',
  SET_ALUMNUS_VALIDATE_DUPLICATE = 'SET_ALUMNUS_VALIDATE_DUPLICATE',
  SET_INFO_ALUMNUS = 'SET_INFO_ALUMNUS',
  SET_VALIDATE_POST_ALUMNUS = 'SET_VALIDATE_POST_ALUMNUS',
  SET_VALIDATE_POST_COMMUNITY = 'SET_VALIDATE_POST_COMMUNITY',
}

export enum RegisterActions {
  SET_INVITE = 'SET_INVITE',
  SET_COMMUNITY_REQUIRED_COLUMNS = 'SET_COMMUNITY_REQUIRED_COLUMNS',
  SET_TERMS_SERVICE_YELLOOP = 'SET_TERMS_SERVICE_YELLOOP',
  SET_TERMS_SERVICE_COMMUNITY = 'SET_TERMS_SERVICE_COMMUNITY',
  SET_LIST_JOBS = 'SET_LIST_JOBS',

  SET_REGISTER_VALUE = 'SET_REGISTER_VALUE',
  SET_REGISTER_COMMUNITY_VALUE = 'SET_REGISTER_COMMUNITY_VALUE',
  SET_INFO_ALUMNUS = 'SET_INFO_ALUMNUS',
  SET_ALUMNUS_VALIDATE_DUPLICATE = 'SET_ALUMNUS_VALIDATE_DUPLICATE',
  SET_VALIDATE_POST_ALUMNUS = 'SET_VALIDATE_POST_ALUMNUS',
  SET_VALIDATE_POST_COMMUNITY = 'SET_VALIDATE_POST_COMMUNITY',
}
//USER
export enum UserGetters {
  JOIN_USER_VALUE = 'JOIN_USER_VALUE',
  CHECK_SUBMIT_FORM_USERS = 'CHECK_SUBMIT_FORM_USERS',
  INVITE_COMMUNITY = 'INVITE_COMMUNITY',
  TERMS_YELLOOP = 'TERMS_YELLOOP',
  TERMS_COMMUNITY = 'TERMS_COMMUNITY',
  FIRST_TIME = 'FIRST_TIME',
  ALUMNI_PROFILE = 'ALUMNI_PROFILE',
  GET_MAIL_INVITE = 'GET_MAIL_INVITE',
}

export enum UserMutations {
  SET_JOIN_USER_VALUE = 'SET_JOIN_USER_VALUE',
  SET_CHECK_SUBMIT_FORM_USERS = 'SET_CHECK_SUBMIT_FORM_USERS',
  SET_INVITE_COMMUNITY = 'SET_INVITE_COMMUNITY',

  SET_TERMS_YELLOOP = 'SET_TERMS_YELLOOP',
  SET_TERMS_COMMUNITY = 'SET_TERMS_COMMUNITY',
  SET_FIRST_TIME = 'SET_FIRST_TIME',
  SET_ALUMNI_PROFILE = 'SET_ALUMNI_PROFILE',
  SET_MAIL_INVITE = 'SET_MAIL_INVITE',
}

export enum UserActions {
  SET_JOIN_USER_VALUE = 'SET_JOIN_USER_VALUE',
  SET_CHECK_SUBMIT_FORM_USERS = 'SET_CHECK_SUBMIT_FORM_USERS',
  SET_INVITE_COMMUNITY = 'SET_INVITE_COMMUNITY',

  SET_TERMS_YELLOOP = 'SET_TERMS_YELLOOP',

  SET_TERMS_COMMUNITY = 'SET_TERMS_COMMUNITY',
  SET_FIRST_TIME = 'SET_FIRST_TIME',
  SET_ALUMNI_PROFILE = 'SET_ALUMNI_PROFILE',
  UPDATE_ALUMNI_PROFILE = 'UPDATE_ALUMNI_PROFILE',
  SET_MAIL_INVITE = 'SET_MAIL_INVITE',
}
//ACCOUNT
export enum AccountGetters {
  EDIT_NORMAL_DATA = 'EDIT_NORMAL_DATA',
  EMAIL_DATA = 'EMAIL_DATA',
  PASSWORD_DATA = 'PASSWORD_DATA',

  PROFILE_DETAIL = 'PROFILE_DETAIL',
  POINT_HISTORY = 'POINT_HISTORY',
  LIST_USER_WITH_DELETED = 'LIST_USER_WITH_DELETED',
}

export enum AccountMutations {
  SET_EDIT_NORMAL_DATA = 'SET_EDIT_NORMAL_DATA',
  SET_EMAIL_DATA = 'SET_EMAIL_DATA',
  SET_PASSWORD_DATA = 'SET_PASSWORD_DATA',

  SET_PROFILE_DETAIL = 'SET_PROFILE_DETAIL',
  SET_POINT_HISTORY = 'SET_POINT_HISTORY',
  SET_PROFILE_ADMIN_DETAIL = 'SET_PROFILE_ADMIN_DETAIL',
  SHOW_MODAL = 'SHOW_MODAL',
  SET_USER_WITH_DELETED = 'SET_USER_WITH_DELETED',
}

export enum AccountActions {
  SET_EDIT_NORMAL_DATA = 'SET_EDIT_NORMAL_DATA',
  UPDATE_EDIT_NORMAL_DATA = 'UPDATE_EDIT_NORMAL_DATA',
  SET_EMAIL_DATA = 'SET_EMAIL_DATA',
  SET_PASSWORD_DATA = 'SET_PASSWORD_DATA',

  SET_PROFILE_DETAIL = 'SET_PROFILE_DETAIL',
  SET_PROFILE_ADMIN_DETAIL = 'SET_PROFILE_ADMIN_DETAIL',
  SET_POINT_HISTORY = 'SET_POINT_HISTORY',
  SET_USER_WITH_DELETED = 'SET_USER_WITH_DELETED',
}
//CONVERSATIONS
export enum ConversationsGetters {
  MESSAGES_DATA = 'MESSAGES_DATA',
  CHATROOMS = 'CHATROOMS',
  MESSAGE_DETAIL = 'MESSAGE_DETAIL',
  LIST_MANAGEMENT_NO = 'LIST_MANAGEMENT_NO',
  GET_ALL_DATA = 'GET_ALL_DATA',
  GET_DATA_SEARCH_DETAIL = 'GET_DATA_SEARCH_DETAIL',
}

export enum ConversationsMutations {
  SET_MESSAGES_DATA = 'SET_MESSAGES_DATA',
  UPDATE_LIST_MESSAGES = 'UPDATE_LIST_MESSAGES',

  SET_CHATROOMS = 'SET_CHATROOMS',
  SET_LOADING = 'SET_LOADING',
  SET_MESSAGE_DETAIL = 'SET_MESSAGE_DETAIL',
  SET_LIST_MANAGEMENT_NO = 'SET_LIST_MANAGEMENT_NO',
  CHECK_ALL_MANAGEMENT_NO = 'CHECK_ALL_MANAGEMENT_NO',
  SET_ALL_DATA = 'SET_ALL_DATA',
  SET_DATA_SEARCH_DETAIL = 'SET_DATA_SEARCH_DETAIL',
  CLEAR_DATA = 'CLEAR_DATA',
}

export enum ConversationsActions {
  SET_MESSAGES_DATA = 'SET_MESSAGES_DATA',
  UPDATE_LIST_MESSAGES = 'UPDATE_LIST_MESSAGES',
  SET_CHATROOMS = 'SET_CHATROOMS',
  SET_MESSAGE_DETAIL = 'SET_MESSAGE_DETAIL',
  SET_LIST_MANAGEMENT_NO = 'SET_LIST_MANAGEMENT_NO',
  CHECK_ALL_MANAGEMENT_NO = 'CHECK_ALL_MANAGEMENT_NO',
  SET_ALL_DATA = 'SET_ALL_DATA',
  SET_DATA_SEARCH_DETAIL = 'SET_DATA_SEARCH_DETAIL',
}

// USER MANAGEMENT
export enum UserManagementGetters {
  USER_FLAG_DATA = 'USER_FLAG_DATA',
  LIST_USERS = 'LIST_USERS',
  ADMINNISTRATOR_INFOR = 'ADMINNISTRATOR_INFOR',
  LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS',
  LIST_MESSAGE_TEMPLATES = 'LIST_MESSAGE_TEMPLATES',
}
export enum UserManagementMutations {
  SET_USER_FLAG_DATA = 'SET_USER_FLAG_DATA',
  SET_LIST_USERS = 'SET_LIST_USERS',
  UPDATE_LIST_USERS = 'UPDATE_LIST_USERS',
  DELETE_USER_FLAG = 'DELETE_USER_FLAG',
  SET_ADMINNISTRATOR_INFOR = 'SET_ADMINNISTRATOR_INFOR',
  SET_LIST_NOTIFICATIONS = 'SET_LIST_NOTIFICATIONS',
  SET_LIST_MESSAGE_TEMPLATES = 'SET_LIST_MESSAGE_TEMPLATES',
  SET_ERROR = 'SET_ERROR',
  LOADING = 'LOADING',
}
export enum UserManagementActions {
  SET_USER_FLAG_DATA = 'SET_USER_FLAG_DATA',
  SET_LIST_USERS = 'SET_LIST_USERS',
  UPDATE_LIST_USERS = 'UPDATE_LIST_USERS',
  DELETE_USER_FLAG = 'DELETE_USER_FLAG',
  SET_ADMINNISTRATOR_INFOR = 'SET_ADMINNISTRATOR_INFOR',
  SET_LIST_NOTIFICATIONS = 'SET_LIST_NOTIFICATIONS',
  SET_LIST_MESSAGE_TEMPLATES = 'SET_LIST_MESSAGE_TEMPLATES',
}

// COMMUNITY
export enum CommunityGetters {
  COMMUNITY_INFO = 'COMMUNITY_INFO',
  GET_ME = 'GET_ME',
  COMMUNITY = 'COMMUNITY',
  GET_LOADING = 'GET_LOADING',
  GET_INTEREST_LIST_VISIBLE_OPTION = 'GET_INTEREST_LIST_VISIBLE_OPTION',
}
export enum CommunityMutations {
  SET_COMMUNITY_INFO = 'SET_COMMUNITY_INFO',
  UPDATE_COMMUNITY_INFO = 'UPDATE_COMMUNITY_INFO',
  SET_ME = 'SET_ME',
  LOG_OUT = 'LOG_OUT',
  LOADING = 'LOADING',
  CHANGE_IMAGE_URL_ME = 'CHANGE_IMAGE_URL_ME',
  SET_COMMUNITY = 'SET_COMMUNITY',
}
export enum CommunityActions {
  SET_COMMUNITY_INFO = 'SET_COMMUNITY_INFO',
  UPDATE_COMMUNITY_INFO = 'UPDATE_COMMUNITY_INFO',
  SET_ME = 'SET_ME',
  UPDATE_ME = 'UPDATE_ME',
  LOG_OUT = 'LOG_OUT',
  CHANGE_IMAGE_URL_ME = 'CHANGE_IMAGE_URL_ME',
  GET_COMMUNITY = 'GET_COMMUNITY',
}

//COMMUNITIES PORTFOLIOS
export enum CommunityPortfoliosGetters {
  COMMUNITIES_PORTFOLIOS = 'COMMUNITIES_PORTFOLIOS',
  ACTIVITIES_PORTFOLIOS = 'ACTIVITIES_PORTFOLIOS',
  PORTFOLIOS_DEPARTMENTS = 'PORTFOLIOS_DEPARTMENTS',
}
export enum CommunityPortfoliosMutations {
  SET_COMMUNITIES_PORTFOLIOS = 'SET_COMMUNITIES_PORTFOLIOS',
  SET_ACTIVITIES_PORTFOLIOS = 'SET_ACTIVITIES_PORTFOLIOS',

  CREATE_COMMUNITIES_PORTFOLIOS = 'CREATE_COMMUNITIES_PORTFOLIOS',
  UPDATE_COMMUNITIES_PORTFOLIOS = 'UPDATE_COMMUNITIES_PORTFOLIOS',
  DELETE_COMMUNITIES_PORTFOLIOS = 'DELETE_COMMUNITIES_PORTFOLIOS',
  UPDATE_PORTFOLIOS = 'UPDATE_PORTFOLIOS',

  CREATE_ACTIVITIES_PORTFOLIOS = 'CREATE_ACTIVITIES_PORTFOLIOS',
  UPDATE_ACTIVITIES_PORTFOLIOS = 'UPDATE_ACTIVITIES_PORTFOLIOS',
  DELETE_ACTIVITIES_PORTFOLIOS = 'DELETE_ACTIVITIES_PORTFOLIOS',

  SET_PORTFOLIOS_DEPARTMENTS = 'SET_PORTFOLIOS_DEPARTMENTS',
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',

  UPDATE_ACTIVITIES_PORTFOLIOS_DRAG = 'UPDATE_ACTIVITIES_PORTFOLIOS_DRAG',
  UPDATE_ACTIVITIES_DRAG = 'UPDATE_ACTIVITIES_DRAG',
}
export enum CommunityPortfoliosActions {
  SET_COMMUNITIES_PORTFOLIOS = 'SET_COMMUNITIES_PORTFOLIOS',
  CREATE_COMMUNITIES_PORTFOLIOS = 'CREATE_COMMUNITIES_PORTFOLIOS',
  UPDATE_COMMUNITIES_PORTFOLIOS = 'UPDATE_COMMUNITIES_PORTFOLIOS',
  DELETE_COMMUNITIES_PORTFOLIOS = 'DELETE_COMMUNITIES_PORTFOLIOS',
  UPDATE_PORTFOLIOS = 'UPDATE_PORTFOLIOS',

  CREATE_ACTIVITIES_PORTFOLIOS = 'CREATE_ACTIVITIES_PORTFOLIOS',
  UPDATE_ACTIVITIES_PORTFOLIOS = 'UPDATE_ACTIVITIES_PORTFOLIOS',
  DELETE_ACTIVITIES_PORTFOLIOS = 'DELETE_ACTIVITIES_PORTFOLIOS',

  SET_PORTFOLIOS_DEPARTMENTS = 'SET_PORTFOLIOS_DEPARTMENTS',
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',

  UPDATE_ACTIVITIES_PORTFOLIOS_DRAG = 'UPDATE_ACTIVITIES_PORTFOLIOS_DRAG',
  UPDATE_ACTIVITIES_DRAG = 'UPDATE_ACTIVITIES_DRAG',
}
// POST
export enum PostGetters {
  POSTS = 'POSTS',
  POST = 'POST',
  LIKE = 'LIKE',
  INTEREST = 'INTEREST',
  POST_ID = 'POST_ID',
  ZOOM_IMAGE = 'ZOOM_IMAGE',
  EDIT_POST_STATUS = 'EDIT_POST_STATUS',
  CREATE_POST_STATUS = 'CREATE_POST_STATUS',
  LIST_COMMENT = 'LIST_COMMENT',
  POST_FAILED_MESSAGE_CODE = 'POST_FAILED_MESSAGE_CODE',
  LIST_USER_MENTION_POST = 'LIST_USER_MENTION_POST',
  GET_POST_TYPE = 'GET_POST_TYPE',
}
export enum PostMutations {
  SET_POSTS = 'SET_POSTS',
  SET_POST = 'SET_POST',
  SET_LIKES = 'SET_LIKES',
  SET_INTEREST = 'SET_INTEREST',
  SET_COMMENT = 'SET_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  CREATE_POST = 'CREATE_POST',
  DELETE_POST = 'DELETE_POST',
  UPDATE_POST = 'UPDATE_POST',
  SET_LOADING = 'SET_LOADING',
  SET_FAVORITE = 'SET_FAVORITE',
  ADD_POST = 'ADD_POST',
  REMOVE_ALL_POST = 'REMOVE_ALL_POST',
  SET_CURRENT_POST_ID = 'SET_CURRENT_POST_ID',
  SET_ZOOM_IMAGE = 'SET_ZOOM_IMAGE',
  SET_EDIT_POST_STATUS = 'SET_EDIT_POST_STATUS',
  SET_CREATE_POST_STATUS = 'SET_CREATE_POST_STATUS',
  SET_LIST_COMMENT = 'SET_LIST_COMMENT',
  SET_POST_FAILED_MESSAGE_CODE = 'SET_POST_FAILED_MESSAGE_CODE',
  REMOVE_POST = 'REMOVE_POST',
  SET_USER_MENTION_POST = 'SET_USER_MENTION_POST',
  SET_POST_DETAIL = 'SET_POST_DETAIL_COMMUNITY',
  SET_POST_TYPE = 'SET_POST_TYPE',
}
export enum PostActions {
  GET_POSTS = 'GET_POSTS',
  GET_POST = 'GET_POST',
  GET_LIKES = 'GET_LIKES',
  GET_INTEREST = 'GET_INTEREST',
  SET_COMMENT = 'SET_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  CREATE_POST = 'CREATE_POST',
  DELETE_POST = 'DELETE_POST',
  UPDATE_POST = 'UPDATE_POST',
  SET_FAVORITE = 'SET_FAVORITE',
  ADD_POST = 'ADD_POST',
  REMOVE_ALL_POST = 'REMOVE_ALL_POST',
  SET_ZOOM_IMAGE = 'SET_ZOOM_IMAGE',
  SET_EDIT_POST_STATUS = 'SET_EDIT_POST_STATUS',
  SET_CREATE_POST_STATUS = 'SET_CREATE_POST_STATUS',
  GET_CHAT_ROOM_USERS = 'GET_CHAT_ROOM_USERS',
  SET_LIST_COMMENT = 'SET_LIST_COMMENT',
  SET_POST_FAILED_MESSAGE_CODE = 'SET_POST_FAILED_MESSAGE_CODE',
  REMOVE_POST = 'REMOVE_POST',
  GET_USER_MENTION_POST = 'GET_USER_MENTION_POST',
  SET_POST_DETAIL = 'SET_POST_DETAIL_COMMUNITY',
}
// GROUPS
export enum GroupsGetters {
  LIST_GROUPS = 'LIST_GROUPS',
  ALL_GROUPS = 'ALL_GROUPS',
  LIST_USER_GROUP = 'LIST_USER_GROUP',
  DETAIL_GROUPS = 'DETAIL_GROUPS',
  LIST_TALK_ROOMS = 'LIST_TALK_ROOMS',
  LIST_TALK_ROOMS_LOAD_MORE = 'LIST_TALK_ROOMS_LOAD_MORE',
  DETAIL_TALK_ROOM = 'DETAIL_TALK_ROOM',
  LIST_GROUPS_LOAD_MORE = 'LIST_GROUPS_LOAD_MORE',
  LOADING = 'LOADING',
  LOADING_DETAIL = 'LOADING_DETAIL',
  PREVENT_LOADING_AGAIN = 'PREVENT_LOADING_AGAIN',
  GET_USER_LIST_RECORD_SELECTED = 'GET_USER_LIST_RECORD_SELECTED',
  LIST_MENTION_USER = 'LIST_MENTION_USER',
}
export enum GroupsMutations {
  SET_LIST_GROUPS = 'SET_LIST_GROUPS',
  SET_ALL_GROUPS = 'SET_ALL_GROUPS',

  CREATE_GROUPS = 'CREATE_GROUPS',
  EDIT_GROUPS = 'EDIT_GROUPS',
  DELETE_GROUPS = 'DELETE_GROUPS',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_ADMIN = 'SET_LOADING_ADMIN',
  JOIN_GROUP = 'JOIN_GROUP',
  EXIT_GROUP = 'EXIT_GROUP',
  SET_LIST_USER_GROUP = 'SET_LIST_USER_GROUP',
  SET_DETAIL_GROUPS = 'SET_DETAIL_GROUPS',
  SET_LIST_TALK_ROOMS = 'SET_LIST_TALK_ROOMS',
  SET_LIST_TALK_ROOMS_LOAD_MORE = 'SET_LIST_TALK_ROOMS_LOAD_MORE',
  CREATE_TALK_ROOM = 'CREATE_TALK_ROOM',
  SET_DETAIL_TALK_ROOM = 'SET_DETAIL_TALK_ROOM',
  EDIT_TALK_ROOM = 'EDIT_TALK_ROOM',
  DELETE_TALK_ROOM = 'DELETE_TALK_ROOM',
  SET_LIST_GROUPS_LOAD_MORE = 'SET_LIST_GROUPS_LOAD_MORE',
  SET_LOADING_TALK_ROOM = 'SET_LOADING_TALK_ROOM',
  REMOVE_LIST_GROUPS = 'REMOVE_LIST_GROUPS',
  SET_LOADING_DETAIL = 'SET_LOADING_DETAIL',
  SET_PREVENT_LOADING_AGAIN = 'SET_PREVENT_LOADING_AGAIN',
  SET_LIST_RECORD_SELECTED = 'SET_LIST_RECORD_SELECTED',
  SET_LIST_MENTION_USER = 'SET_LIST_MENTION_USER',
}
export enum GroupsActions {
  SET_LIST_GROUPS = 'SET_LIST_GROUPS',
  SET_ALL_GROUPS = 'SET_ALL_GROUPS',

  CREATE_GROUPS = 'CREATE_GROUPS',
  EDIT_GROUPS = 'EDIT_GROUPS',
  SET_LOADING = 'SET_LOADING',
  DELETE_GROUPS = 'DELETE_GROUPS',
  JOIN_GROUP = 'JOIN_GROUP',
  EXIT_GROUP = 'EXIT_GROUP',
  SET_LIST_USER_GROUP = 'SET_LIST_USER_GROUP',
  SET_DETAIL_GROUPS = 'SET_DETAIL_GROUPS',
  SET_LIST_TALK_ROOMS = 'SET_LIST_TALK_ROOMS',
  SET_LIST_TALK_ROOMS_LOAD_MORE = 'SET_LIST_TALK_ROOMS_LOAD_MORE',
  CREATE_TALK_ROOM = 'CREATE_TALK_ROOM',
  SET_DETAIL_TALK_ROOM = 'SET_DETAIL_TALK_ROOM',
  EDIT_TALK_ROOM = 'EDIT_TALK_ROOM',
  DELETE_TALK_ROOM = 'DELETE_TALK_ROOM',
  SET_LIST_GROUPS_LOAD_MORE = 'SET_LIST_GROUPS_LOAD_MORE',
  SET_LOADING_TALK_ROOM = 'SET_LOADING_TALK_ROOM',
  REMOVE_LIST_GROUPS = 'REMOVE_LIST_GROUPS',
  SET_LOADING_DETAIL = 'SET_LOADING_DETAIL',
  SET_PREVENT_LOADING_AGAIN = 'SET_PREVENT_LOADING_AGAIN',
  SET_LIST_RECORD_SELECTED = 'SET_LIST_RECORD_SELECTED',
  SET_LIST_MENTION_USER = 'SET_LIST_MENTION_USER',
}

//INQUIRY

export enum InquiryActions {
  SET_INQUIRY_CATEGORIES = 'SET_INQUIRY_CATEGORIES',
  SET_INQUIRY_DATA = 'SET_INQUIRY_DATA',
}

export enum InquiryGetters {
  INQUIRY_CATEGORIES = 'INQUIRY_CATEGORIES',
  INQUIRY_DATA = 'INQUIRY_DATA',
}

export enum InquiryMutations {
  SET_INQUIRY_CATEGORIES = 'SET_INQUIRY_CATEGORIES',
  SET_INQUIRY_DATA = 'SET_INQUIRY_DATA',
}

//alumni-register-form
export enum AlumniRegisterAdminGetters {
  ENTRY_FORM = 'ENTRY_FORM',
  TERMS_OF_SERVICES = 'TERMS_OF_SERVICES',
  DATA_CHECK = 'DATA_CHECK',
}

export enum AlumniRegisterAdminActions {
  SET_ENTRY_FORM = 'SET_ENTRY_FORM',
  SET_TERMS_OF_SERVICES = 'SET_TERMS_OF_SERVICES',
  CHANGE_TERMS_OF_SERVICES = 'CHANGE_TERMS_OF_SERVICES',
  SET_DATA_CHECK = 'SET_DATA_CHECK',
}

export enum AlumniRegisterAdminMutations {
  SET_ENTRY_FORM = 'SET_ENTRY_FORM',
  SET_TERMS_OF_SERVICES = 'SET_TERMS_OF_SERVICES',
  CHANGE_TERMS_OF_SERVICES = 'CHANGE_TERMS_OF_SERVICES',
  SET_DATA_CHECK = 'SET_DATA_CHECK',
}
// CHAT
export enum ChatGetters {
  CHAT_ROOM = 'CHAT_ROOM',
  GET_MESSAGE_ID = 'GET_MESSAGE_ID',
  TALK_ROOM = 'TALK_ROOM',
  GET_DIRECT_CHAT = 'GET_DIRECT_CHAT',
  LOADING_CHAT = 'LOADING_CHAT',
  CHAT_BY_ADMIN = 'CHAT_BY_ADMIN',
  PAGE_CHAT = 'PAGE_CHAT',
  TOTAL_PAGES_CHAT_ROOM = 'TOTAL_PAGES_CHAT_ROOM',
  GET_MESSAGE_SOCKET = 'GET_MESSAGE_SOCKET',
}
export enum ChatMutations {
  SET_CHAT_ROOM = 'SET_CHAT_ROOM',
  SET_MESSAGE = 'SET_MESSAGE',
  ADD_MESSAGE = 'ADD_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  REMOVE_MESSAGE_TALK_ROOM = 'REMOVE_MESSAGE_TALK_ROOM',
  SET_MESSAGE_ID = 'SET_MESSAGE_ID',
  SET_TALK_ROOM = 'SET_TALK_ROOM',
  SET_MESSAGE_TALK_ROOM = 'SET_MESSAGE_TALK_ROOM',
  SET_DIRECT_CHAT = 'SET_DIRECT_CHAT',
  SET_MESSAGE_DIRECT = 'SET_MESSAGE_DIRECT',
  REMOVE_DIRECT_MESSAGE = 'REMOVE_DIRECT_MESSAGE',
  UPDATE_STATUS_MESSAGE = 'UPDATE_STATUS_MESSAGE',
  SET_LOADING_CHAT = 'SET_LOADING_CHAT',
  UPDATE_STATUS_MESSAGE_SOCKET = 'UPDATE_STATUS_MESSAGE_SOCKET',
  SET_PAGE_CHAT = 'SET_PAGE_CHAT',
  CHAT_BY_ADMIN = 'CHAT_BY_ADMIN',
  SET_MESSAGE_SOCKET = 'SET_MESSAGE_SOCKET',
}
export enum ChatActions {
  GET_CHAT_ROOM = 'GET_CHAT_ROOM',
  SEND_MESSAGE = 'SEND_MESSAGE',
  RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
  RECEIVE_MESSAGE_TALK_ROOM = 'RECEIVE_MESSAGE_TALK_ROOM',
  REMOVE_MESSAGE_TALK_ROOM = 'REMOVE_MESSAGE_TALK_ROOM',
  REMOVE_MESSAGE_TALK_ROOM_WS = 'REMOVE_MESSAGE_TALK_ROOM_WS',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
  REMOVE_MESSAGE_WS = 'REMOVE_MESSAGE_WS',
  SET_MESSAGE_ID = 'SET_MESSAGE_ID',
  GET_TALK_ROOM = 'GET_TALK_ROOM',
  SEND_MESSAGE_TALK_ROOM = 'SEND_MESSAGE_TALK_ROOM',
  GET_DIRECT_CHAT = 'GET_DIRECT_CHAT',
  SEND_MESSAGE_DIRECT = 'SEND_MESSAGE_DIRECT',
  RECEIVE_DIRECT_MESSAGE_WS = 'RECEIVE_DIRECT_MESSAGE_WS',
  REMOVE_DIRECT_MESSAGE = 'REMOVE_DIRECT_MESSAGE',
  REMOVE_DIRECT_MESSAGE_WS = 'REMOVE_DIRECT_MESSAGE_WS',
  ADD_DIRECT_MESSAGE = 'ADD_DIRECT_MESSAGE',
  UPDATE_STATUS_MESSAGE = 'UPDATE_STATUS_MESSAGE',
  SET_LOADING_CHAT = 'SET_LOADING_CHAT',
  UPDATE_STATUS_MESSAGE_SOCKET = 'UPDATE_STATUS_MESSAGE_SOCKET',
  SET_PAGE_CHAT = 'SET_PAGE_CHAT',
  SET_MESSAGE_SOCKET = 'SET_MESSAGE_SOCKET',
}

//COMMUNITY ALUMNUS CANDIDATES

export enum UserCandidatesGetters {
  GET_LIST_USERS = 'GET_LIST_USERS',
  GET_CANDIDATE_DETAIL = 'GET_CANDIDATE_DETAIL',
}

export enum UserCandidatesActions {
  SET_USERS_CANDIDATES = 'SET_USERS_CANDIDATES',
  SET_CANDIDATE_DETAIL = 'SET_CANDIDATE_DETAIL',
}

export enum UserCandidatesMutations {
  SET_USERS_CANDIDATES = 'SET_USERS_CANDIDATES',
  SET_CANDIDATE_DETAIL = 'SET_CANDIDATE_DETAIL',
  LOADING_GET_DETAIL = 'LOADING_GET_DETAIL',
  SET_ERROR_DETAIL = 'SET_ERROR_DETAIL',
  LOADING = 'LOADING',
}

//COMMUNITY ALUMNUS
export enum CommunityAlumnusGetters {
  GET_LIST_COMMUNITY_ALUMNUS = 'GET_LIST_COMMUNITY_ALUMNUS',
  GET_DETAIL_ALMNUS = 'GET_DETAIL_ALMNUS',
  GET_LIST_MANAGING_FLAGS = 'GET_LIST_MANAGING_FLAGS',
  ALUMNAI_SELECTED = 'ALUMNAI_SELECTED',
  GET_LIST_RECORD_SELECTED = 'GET_LIST_RECORD_SELECTED',
  GET_DATA_SEARCH_ALUMNUS = 'GET_DATA_SEARCH_ALUMNUS',
}

export enum CommunityAlumnusActions {
  SET_LIST_COMMUNITY_ALUMNUS = 'SET_LIST_COMMUNITY_ALUMNUS',
  SET_DETAIL_ALMNUS = 'SET_DETAIL_ALMNUS',
  SET_LIST_MANAGING_FLAGS = 'SET_LIST_MANAGING_FLAGS',
  SET_ALUMNAI_SELECTED = 'SET_ALUMNAI_SELECTED',
  SET_LIST_RECORD_SELECTED = 'SET_LIST_RECORD_SELECTED',
  UPDATE_DETAIL_ALMUNUS = 'UPDATE_DETAIL_ALMUNUS',
  SET_DATA_SEARCH_ALUMNUS = 'SET_DATA_SEARCH_ALUMNUS',
}

export enum CommunityAlumnusMutations {
  SET_LIST_COMMUNITY_ALUMNUS = 'SET_LIST_COMMUNITY_ALUMNUS',
  SET_DETAIL_ALMNUS = 'SET_DETAIL_ALMNUS',
  SET_LIST_MANAGING_FLAGS = 'SET_LIST_MANAGING_FLAGS',
  SET_ALUMNAI_SELECTED = 'SET_ALUMNAI_SELECTED',
  SET_LIST_RECORD_SELECTED = 'SET_LIST_RECORD_SELECTED',
  LOADING = 'LOADING',
  UPDATE_DETAIL_ALMUNUS = 'UPDATE_DETAIL_ALMUNUS',
  SET_DATA_SEARCH_ALUMNUS = 'SET_DATA_SEARCH_ALUMNUS',
}

//COMMUNITY USERS
export enum CommunityUsersGetters {
  GET_LIST_COMMUNITY_USERS = 'GET_LIST_COMMUNITY_USERS',
  GET_USER_DETAIL = 'GET_USER_DETAIL',
  USER_SELECTED = 'USER_SELECTED',
  GET_ALL_COMMUNITY_USERS = 'GET_ALL_COMMUNITY_USERS',
  GET_DATA_SEARCH = 'GET_DATA_SEARCH',
}

export enum CommunityUsersActions {
  SET_LIST_COMMUNITY_USERS = 'SET_LIST_COMMUNITY_USERS',
  SET_USER_DETAIL = 'SET_USER_DETAIL',
  SET_USER_SELECTED = 'SET_USER_SELECTED',
  UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL',
  SET_ALL_COMMUNITY_USERS = 'SET_ALL_COMMUNITY_USERS',
  SET_DATA_SEARCH = 'SET_DATA_SEARCH',
}

export enum CommunityUsersMutations {
  SET_LIST_COMMUNITY_USERS = 'SET_LIST_COMMUNITY_USERS',
  SET_USER_DETAIL = 'SET_USER_DETAIL',
  SET_USER_SELECTED = 'SET_USER_SELECTED',
  LOADING = 'LOADING',
  UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL',
  SET_ALL_COMMUNITY_USERS = 'SET_ALL_COMMUNITY_USERS',
  SET_DATA_SEARCH = 'SET_DATA_SEARCH',
}

// USER POST
export enum UserPostGetters {
  GET_POST = 'GET_USER_POST',
  GET_COMMENT = 'GET_COMMENT',
  GET_POST_OPERATIONAL = 'GET_POST_OPERATIONAL',
  SHOW_ALL_COMMENTS = 'SHOW_ALL_COMMENTS',
  GET_POST_DETAIL = 'GET_POST_DETAIL',
}

export enum UserPostActions {
  GET_POST = 'GET_USER_POST',
  CREATE_POST = 'CREATE_ADMIN_POST',
  UPDATE_POST = 'UPDATE_ADMIN_POST',
  DELETE_POST = 'DELETE_ADMIN_POST',
  DELETE_COMMENT = 'DELETE_COMMENT',
  SET_LOADING_POSTS = 'SET_LOADING_POSTS',
  SET_CURRENT_COMMENT = 'SET_CURRENT_COMMENT',
  UPDATE_STATUS_COMMENT = 'UPDATE_STATUS_COMMENT',
  UPDATE_LIKE_INTEREST_POST = 'UPDATE_LIKE_INTEREST_POST',
  GET_OPERATIONAL_POST = 'GET_OPERATIONAL_POST',
  SET_SHOW_ALL_COMMENTS = 'SET_SHOW_ALL_COMMENTS',
  SET_LIST_COMMENT_USER_POST = 'SET_LIST_COMMENT_USER_POST',
  SET_POST_DETAIL = 'SET_POST_DETAIL',
  REMOVE_POST = 'REMOVE_POST',
}

export enum UserPostMutations {
  GET_POST = 'GET_USER_POST',
  UPDATE_POST = 'UPDATE_ADMIN_POST',
  ADD_COMMENT = 'ADD_COMMENT',
  REMOVE_COMMENT = 'REMOVE_COMMENT',
  SET_CURRENT_COMMENT = 'SET_CURRENT_COMMENT',
  UPDATE_STATUS_COMMENT = 'UPDATE_STATUS_COMMENT',
  GET_OPERATIONAL_POST = 'GET_OPERATIONAL_POST',
  SET_LOADING_POSTS = 'SET_LOADING_POSTS',
  SET_SHOW_ALL_COMMENTS = 'SET_SHOW_ALL_COMMENTS',
  SET_LIST_COMMENT_USER_POST = 'SET_LIST_COMMENT_USER_POST',
  SET_POST_DETAIL = 'SET_POST_DETAIL',
  REMOVE_POST = 'REMOVE_POST',
  UPDATE_LIKE_INTEREST_POST = 'UPDATE_LIKE_INTEREST_POST',
}

//CONTRACT INFORMATION
export enum ContractInformationGetters {
  CONTRACT_INFORMATION = 'CONTRACT_INFORMATION',
  CONTRACT_HISTORY = 'CONTRACT_HISTORY',
}

export enum ContractInformationActions {
  SET_CONTRACT_INFORMATION = 'SET_CONTRACT_INFORMATION',
  SET_CONTRACT_HISTORY = 'SET_CONTRACT_HISTORY',
}

export enum ContractInformationMutations {
  SET_CONTRACT_INFORMATION = 'SET_CONTRACT_INFORMATION',
  SET_CONTRACT_HISTORY = 'SET_CONTRACT_HISTORY',
  LOADING = 'LOADING',
}

//COMMUNITY CHAT LIST HISTORIES
export enum CommunityChatListGetters {
  CHAT_LIST_HISTORIES = 'CHAT_LIST_HISTORIES',
}

export enum CommunityChatListActions {
  SET_CHAT_LIST_HISTORIES = 'SET_CHAT_LIST_HISTORIES',
}

export enum CommunityChatListMutations {
  SET_CHAT_LIST_HISTORIES = 'SET_CHAT_LIST_HISTORIES',
  LOADING = 'LOADING',
}

//POINT
export enum PointGetters {
  POINT_HISTORIES = 'POINT_HISTORIES',
  POINTS = 'POINTS',
  LOADING_POINTS = 'LOADING_POINTS',
  LOADING_POINT_HISTORIES = 'LOADING_POINT_HISTORIES',
  PAGE = 'PAGE',
  EXCHANGE_HISTORIES = 'EXCHANGE_HISTORIES',
  PAGE_EXCHANGE = 'PAGE_EXCHANGE',
  LOADING_EXCHANGE_HISTORIES = 'LOADING_EXCHANGE_HISTORIES',
}

export enum PointActions {
  SET_POINT_HISTORIES = 'SET_POINT_HISTORIES',
  SET_POINTS = 'SET_POINTS',
  SET_LOADING_POINT_HISTORIES = 'SET_LOADING_POINT_HISTORIES',
  SET_PAGE = 'SET_PAGE',
  SET_EXCHANGE_HISTORIES = 'SET_EXCHANGE_HISTORIES',
  SET_PAGE_EXCHANGE = 'SET_PAGE_EXCHANGE',
  SET_LOADING_EXCHANGE_HISTORIES = 'SET_LOADING_EXCHANGE_HISTORIES',
}

export enum PointMutations {
  SET_POINT_HISTORIES = 'SET_POINT_HISTORIES',
  SET_POINTS = 'SET_POINTS',
  SET_LOADING_POINTS = 'SET_LOADING_POINTS',
  SET_LOADING_POINT_HISTORIES = 'SET_LOADING_POINT_HISTORIES',
  SET_PAGE = 'SET_PAGE',
  SET_EXCHANGE_HISTORIES = 'SET_EXCHANGE_HISTORIES',
  SET_PAGE_EXCHANGE = 'SET_PAGE_EXCHANGE',
  SET_LOADING_EXCHANGE_HISTORIES = 'SET_LOADING_EXCHANGE_HISTORIES',
}

//dashboard

export enum DashboardGetters {
  LATEST_ANALYTIC_DATE = 'LATEST_ANALYTIC_DATE',
}

export enum DashboardActions {
  SET_LATEST_ANALYTIC_DATE = 'SET_LATEST_ANALYTIC_DATE',
}

export enum DashboardMutations {
  SET_LATEST_ANALYTIC_DATE = 'SET_LATEST_ANALYTIC_DATE',
}

// Community Site
export enum CommunitySiteAdminGetters {
  ARTICLE_LIST = 'ARTICLE_LIST',
  TOTAL_PAGES = 'TOTAL_PAGES',
  TOTAL_RECORDS = 'TOTAL_RECORDS',
  GET_ARTICLE = 'GET_ARTICLE',
  IMAGE_MAPPING = 'IMAGE_MAPPING',
  RESET_FLAG = 'RESET_FLAG',
  ARTICLE_DETAIL = 'GET_ARTICLE_DETAIL',
  COMMENTS = 'COMMENTS',
  GET_CURRENT_QUERY = 'SITE_GET_CURRENT_QUERY',
}

export enum CommunitySiteAdminActions {
  GET_ARTICLE_LIST = 'GET_ARTICLE_LIST',
  SET_ARTICLE_DATA = 'SET_ARTICLE_DATA',
  CLEAR_FORM_ARTICLE_DATA = 'CLEAR_FORM_ARTICLE_DATA',
  UPDATE_RESET_FLAG = 'UPDATE_RESET_FLAG',
  GET_ARTICLE_DETAIL = 'GET_ARTICLE_DETAIL',
  GET_COMMENT_LIST = 'GET_COMMENT_LIST',
  UPDATE_COMMENT = 'SITE_UPDATE_COMMENT',
  CLEAR_SELECTED_DATA = 'SITE_CLEAR_SELECTED_ARTICLE_DATA',
  GET_USER_MENTION_LIST = 'SITE_GET_USER_MENTION_LIST',
  STORE_CURRENT_QUERY = 'SITE_STORE_CURRENT_QUERY',
}

export enum CommunitySiteAdminMutations {
  SET_ARTICLE_LIST = 'SET_ARTICLE_LIST',
  SET_ARTICLE = 'SET_ARTICLE',
  SET_IMAGE_MAPPING = 'SET_IMAGE_MAPPING',
  CLEAR_ARTICLE_DATA = 'CLEAR_ARTICLE_DATA',
  UPDATE_RESET_FLAG = 'UPDATE_RESET_FLAG',
  GET_ARTICLE_DETAIL = 'GET_ARTICLE_DETAIL',
  SET_COMMENT = 'SITE_SET_COMMENT',
  DELETE_COMMENT = 'SITE_DELETE_COMMENT',
  UPDATE_COMMENT = 'SITE_UPDATE_COMMENT',
  CLEAR_SELECTED_DATA = 'SITE_CLEAR_SELECTED_DATA',
  SET_CURRENT_QUERY = 'SITE_SET_CURRENT_QUERY',
  UPDATE_LIST = 'SITE_UPDATE_LIST',
}

export enum CommunitySiteGetters {
  COMMUNITY_SITE_POSTS = 'COMMUNITY_SITE_POSTS',
  COMMUNITY_SITE_POST_DETAIL = 'COMMUNITY_SITE_POST_DETAIL',
  USER_MENTION_CSP = 'USER_MENTION_CSP',
}

export enum CommunitySiteMutations {
  SET_COMMUNITY_SITE_POSTS = 'SET_COMMUNITY_SITE_POSTS',
  SET_COMMUNITY_SITE_POST_DETAIL = 'SET_COMMUNITY_SITE_POST_DETAIL',
  SET_LOADING_CSP = 'SET_LOADING_CSP',
  SET_LIST_COMMENT_CSP = 'SET_LIST_COMMENT_CSP',
  SET_COMMENT_CSP = 'SET_COMMENT_CSP',
  DELETE_COMMENT_CSP = 'DELETE_COMMENT_CSP',
  UPDATE_LIKE_CSP = 'UPDATE_LIKE_CSP',
  SET_USER_MENTION_CSP = 'SET_USER_MENTION_CSP',
}

export enum CommunitySiteActions {
  GET_COMMUNITY_SITE_POSTS = 'GET_COMMUNITY_SITE_POSTS',
  GET_COMMUNITY_SITE_POST_DETAIL = 'GET_COMMUNITY_SITE_POST_DETAIL',
  SET_LIST_COMMENT_CSP = 'SET_LIST_COMMENT_CSP',
  SET_COMMENT_CSP = 'SET_COMMENT_CSP',
  DELETE_COMMENT_CSP = 'DELETE_COMMENT_CSP',
  UPDATE_LIKE_CSP = 'UPDATE_LIKE_CSP',
  GET_USER_MENTION_CSP = 'GET_USER_MENTION_CSP',
}
