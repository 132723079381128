import { AUTH_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { AuthStateTypes } from '.'

export const mutations: MutationTree<AuthStateTypes> = {
  [AUTH_STORE.MUTATIONS.SET_AUTH_VALUE](state: AuthStateTypes, payload: any) {
    state.authenticated = payload
  },
  [AUTH_STORE.MUTATIONS.SET_FORGOT_EMAIL_DATA](state: AuthStateTypes, payload: any) {
    state.forgotEmailData = payload
  },
  [AUTH_STORE.MUTATIONS.SET_NOTIFICATIONS](state: AuthStateTypes, payload: any) {
    state.notifications = payload
  },
  [AUTH_STORE.MUTATIONS.SET_NOTIFICATIONS_COMMUNITY](state: AuthStateTypes, payload: any) {
    state.notificationsCommunity = payload
  },
  [AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_YELLOOP](state: AuthStateTypes, payload: any) {
    state.numberUnread = payload
  },
  [AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_COMMUNITY](state: AuthStateTypes, payload: any) {
    state.numberUnreadCommunity = payload
  },
  [AUTH_STORE.MUTATIONS.CHANGE_IS_READ](state: AuthStateTypes, payload: any) {
    if (payload.role === 'yelloop') {
      state.notifications?.map((e: any) => {
        if (e.id === payload.id && !e.is_read) {
          e.is_read = true
          state.numberUnread = { ...state.numberUnread, total_unread_notification: state.numberUnread.total_unread_notification - 1 }
        }
        return e
      })
    } else {
      state.notificationsCommunity?.map((e: any) => {
        if (e.id === payload.id && !e.is_read) {
          e.is_read = true
          state.numberUnreadCommunity = { ...state.numberUnreadCommunity, unread_notification_count: state.numberUnreadCommunity.unread_notification_count - 1 }
        }
        return e
      })
    }
  },
  [AUTH_STORE.MUTATIONS.SET_DETAIL_NOTIFICATIONS](state: AuthStateTypes, payload: any) {
    state.detailNotification = payload
  },
  [AUTH_STORE.MUTATIONS.SET_NOTIFICATION_FROM_ADMIN](state: AuthStateTypes, payload: boolean) {
    state.notificationFromAdmin = payload
  },
}
