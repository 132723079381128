export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_REGEX =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#~{}\[\]\\\/_|'<>.^*()%!-])|(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#~{}\[\]\\\/_|'<>.^*()%!-])|(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#~{}\[\]\\\/_|'<>.^*()%!-])/
export const REGEX_SPACE = /^\S+$/
export const PASSWORD_FORMAT_REGEX = /(?=.*[A-Z])|(?=.*[a-z])|(?=.*[$&+,:;=?@#~{}\[\]\\\/_|'<>.^*()%!-])|(?=.*[0-9])/
export const SPECIAL_WORDS = ['password', 'passwd', 'test', 'admin', 'administrator', 'sysadmin']

//姓名や地名(住所)に使われ得る文字群としてJIS2004拡張文字の中でサロゲートペアにあたる304文字
const allowedSurrogatePattern =
  '(?!\uD840\uDC0B|\uD844\uDE3D|\uD844\uDF1B|\uD845\uDC6E|\uD846\uDCBD|\uD842\uDF9F|\uD845\uDEB4|\uD847\uDE34|\uD84C\uDDC4|' +
  '\uD84D\uDDC4|\uD84D\uDF3F|\uD84D\uDF63|\uD84F\uDCFE|\uD851\uDFF1|\uD855\uDC8E|\uD855\uDD0E|\uD855\uDF71|\uD856\uDDC4|' +
  '\uD857\uDDA1|\uD85A\uDEFF|\uD85B\uDE40|\uD85C\uDCF4|\uD85D\uDE84|\uD860\uDE77|\uD860\uDFCD|\uD868\uDD90|\uD840\uDC89|' +
  '\uD840\uDCA2|\uD840\uDCA4|\uD840\uDDA2|\uD840\uDE13|\uD840\uDF2B|\uD840\uDF81|\uD840\uDF71|\uD840\uDFF9|\uD841\uDC4A|' +
  '\uD841\uDD09|\uD841\uDDD6|\uD841\uDE28|\uD841\uDF4F|\uD842\uDC07|\uD842\uDC3A|\uD842\uDCB9|\uD842\uDD7C|\uD842\uDD9D|' +
  '\uD842\uDED3|\uD842\uDF1D|\uD843\uDD45|\uD843\uDDE1|\uD843\uDE95|\uD843\uDE6D|\uD843\uDE64|\uD843\uDF5F|\uD844\uDE01|' +
  '\uD844\uDE55|\uD844\uDE7B|\uD844\uDE74|\uD844\uDEE4|\uD844\uDED7|\uD844\uDEFD|\uD844\uDF36|\uD844\uDF44|\uD844\uDFC4|' +
  '\uD845\uDC6D|\uD845\uDDD7|\uD85B\uDC29|\uD845\uDE47|\uD845\uDF06|\uD845\uDF42|\uD846\uDDC3|\uD847\uDC56|\uD847\uDD2D|' +
  '\uD847\uDD45|\uD847\uDD78|\uD847\uDD62|\uD847\uDDA1|\uD847\uDD9C|\uD847\uDD92|\uD847\uDDB7|\uD847\uDDE0|\uD847\uDE33|' +
  '\uD847\uDF1E|\uD847\uDF76|\uD847\uDFFA|\uD848\uDD7B|\uD848\uDF1E|\uD848\uDFAD|\uD849\uDEF3|\uD84A\uDC5B|\uD84A\uDCAB|' +
  '\uD84A\uDD8F|\uD84A\uDEB8|\uD84A\uDF4F|\uD84A\uDF50|\uD84A\uDF46|\uD84B\uDC1D|\uD84A\uDFA6|\uD84B\uDC24|\uD84B\uDDE1|' +
  '\uD84C\uDDC3|\uD84C\uDDF5|\uD84C\uDDB6|\uD84C\uDF72|\uD84C\uDFD3|\uD84C\uDFD2|\uD84C\uDFD0|\uD84C\uDFE4|\uD84C\uDFD5|' +
  '\uD84C\uDFDA|\uD84C\uDFDF|\uD84D\uDC4A|\uD84D\uDC51|\uD84D\uDC4B|\uD84D\uDC65|\uD84D\uDCE4|\uD84D\uDD5A|\uD84D\uDD94|' +
  '\uD84D\uDE39|\uD84D\uDE47|\uD84D\uDE38|\uD84D\uDE3A|\uD84D\uDF1C|\uD84D\uDF0C|\uD84D\uDF64|\uD84D\uDFFF|\uD84D\uDFE7|' +
  '\uD84E\uDC24|\uD84E\uDC3D|\uD84E\uDE98|\uD84F\uDC7F|\uD84F\uDD00|\uD84F\uDD40|\uD84F\uDDFA|\uD84F\uDDF9|\uD84F\uDDD3|' +
  '\uD84F\uDF7E|\uD850\uDC96|\uD850\uDD03|\uD850\uDDC6|\uD850\uDDFE|\uD850\uDFBC|\uD851\uDE29|\uD851\uDEA5|\uD852\uDC96|' +
  '\uD852\uDE4D|\uD852\uDF56|\uD852\uDF6F|\uD853\uDC16|\uD853\uDD14|\uD853\uDE0E|\uD853\uDE37|\uD853\uDE6A|\uD853\uDE8B|' +
  '\uD854\uDC4A|\uD854\uDC55|\uD854\uDD22|\uD854\uDDA9|\uD854\uDDE5|\uD854\uDDCD|\uD854\uDE1E|\uD854\uDE4C|\uD855\uDC2E|' +
  '\uD855\uDCD9|\uD855\uDDA7|\uD855\uDFA9|\uD855\uDFB4|\uD856\uDDD4|\uD856\uDEE4|\uD856\uDEE3|\uD856\uDEF1|\uD856\uDFB2|' +
  '\uD857\uDC4B|\uD857\uDC64|\uD857\uDE2E|\uD857\uDE56|\uD857\uDE65|\uD857\uDE62|\uD857\uDED8|\uD857\uDEC2|\uD857\uDEE8|' +
  '\uD857\uDF23|\uD857\uDF5C|\uD857\uDFE0|\uD857\uDFD4|\uD858\uDC0C|\uD857\uDFFB|\uD858\uDC17|\uD858\uDC60|\uD858\uDCED|' +
  '\uD858\uDE70|\uD858\uDE86|\uD858\uDF4C|\uD84F\uDD0E|\uD859\uDC02|\uD859\uDE7E|\uD859\uDEB0|\uD859\uDF1D|\uD85A\uDCDD|' +
  '\uD85A\uDCEA|\uD85A\uDD51|\uD85A\uDD6F|\uD85A\uDDDD|\uD85A\uDE1E|\uD85A\uDE58|\uD85A\uDE8C|\uD85A\uDEB7|\uD85B\uDC73|' +
  '\uD85B\uDCDD|\uD85B\uDE65|\uD85B\uDF94|\uD85B\uDFF8|\uD85B\uDFF6|\uD85B\uDFF7|\uD85C\uDD0D|\uD85C\uDD39|\uD85C\uDFDB|' +
  '\uD85C\uDFDA|\uD85C\uDFFE|\uD85D\uDC10|\uD85D\uDC49|\uD85D\uDE15|\uD85D\uDE14|\uD85D\uDE31|\uD85D\uDE93|\uD85D\uDF0E|' +
  '\uD85D\uDF23|\uD85D\uDF52|\uD85E\uDD85|\uD85E\uDE84|\uD85E\uDFB3|\uD85E\uDFBE|\uD85E\uDFC7|\uD85F\uDCB8|\uD85F\uDDA0|' +
  '\uD85F\uDE10|\uD85F\uDFB7|\uD860\uDC8A|\uD860\uDCBB|\uD860\uDE82|\uD860\uDEF3|\uD861\uDC0C|\uD861\uDC55|\uD861\uDD6B|' +
  '\uD861\uDDC8|\uD861\uDDC9|\uD861\uDED7|\uD861\uDEFA|\uD862\uDD49|\uD862\uDD46|\uD862\uDD6B|\uD862\uDD87|\uD862\uDD88|' +
  '\uD862\uDDBA|\uD862\uDDBB|\uD862\uDE1E|\uD862\uDE29|\uD862\uDE71|\uD862\uDE43|\uD862\uDE99|\uD862\uDECD|\uD862\uDEE4|' +
  '\uD862\uDEDD|\uD862\uDFC1|\uD862\uDFEF|\uD863\uDD10|\uD863\uDD71|\uD863\uDDFB|\uD863\uDE1F|\uD863\uDE36|\uD863\uDE89|' +
  '\uD863\uDEEB|\uD863\uDF32|\uD863\uDFF8|\uD864\uDEA0|\uD864\uDEB1|\uD865\uDC90|\uD865\uDDCF|\uD865\uDE7F|\uD865\uDEF0|' +
  '\uD865\uDF19|\uD865\uDF50|\uD866\uDCC6|\uD866\uDE72|\uD867\uDDDB|\uD867\uDE3D|\uD867\uDE15|\uD867\uDE8A|\uD867\uDE49|' +
  '\uD867\uDEC4|\uD867\uDEE9|\uD867\uDEDB|\uD867\uDFCE|\uD868\uDC2F|\uD868\uDC1A|\uD868\uDCF9|\uD868\uDC82|\uD848\uDE18|' +
  '\uD868\uDF8C|\uD869\uDC37|\uD869\uDDF1|\uD869\uDE02|\uD869\uDE1A|\uD869\uDEB2|\uD842\uDFB7)'

// 装飾記号
const pattern_1 = /[\u2700-\u27bf]/

// サロゲートペア文字
const pattern_2 = new RegExp(`(?:\ud83c[\udde6-\uddff]){2}|${allowedSurrogatePattern}[\ud800-\udbff][\udc00-\udfff]`)

// 記号〜数字 + 絵文字の肌の色変更文字 + 数字を1️⃣等に変換する文字
const pattern_3 = /[\u0023-\u0039]\ufe0f?\u20e3/

// ㊙ | ㊗ | 〽 | 〰 | Ⓜ 前後の文字「Ⓛ」「Ⓝ」がNGではない理由は不明
const pattern_4 = /\u3299|\u3297|\u303d|\u3030|\u24c2/

// 🅰 🅱 | 🅾 🅿 | 🆎 | 🆑 ~ 🆚 https://tomari.org/main/java/emoji.html
const pattern_5 = /\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]/

// 🇦 ~ 🇿 | 🈁 🈂 | 🈚 | 🈯 | 🈲 ~ 🈺 | 🉐 🉑
const pattern_6 = /\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]/

// ‼ | ⁉ | ▪ ▫ | ▶ | ◀ | ◻ ◾ | © | ® | ™ | ℹ | 🀄 | ☀ ~ ⛿ https://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/u2600.html
const pattern_7 = /\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]/

// ⬅ | ⬆ | ⬇ | ⬛ | ⬜ | ⭐ | ⭕ | ⌚ | ⌛ | ⌨ | ⏏ | ⏩ ~ ⏳ https://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/u2300.html | ⏸ ~ ⏺ https://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/u2300.html
const pattern_8 = /\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]/

// 🃏 | ⤴ | ⤵ | 数字記号 矢印 https://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/u2190.html
const pattern_9 = /\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]/

export const REGEX_EMOJI = new RegExp(
  `^(?:(?!(?:${pattern_1.source}|${pattern_2.source}|${pattern_3.source}|${pattern_4.source}|${pattern_5.source}|${pattern_6.source}|${pattern_7.source}|${pattern_8.source}|${pattern_9.source}))[^\☺]){1,40000}$`
)

export const AFTER_AVAILABLE = /(?=.*[A-Z])|(?=.*[a-z])|(?=.*[$&+,:;=?@#~{}\[\]\\\/_|'<>.^*()%!-])|(?=.*[0-9])/
// export const REGEX_HTTP = /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim
export const REGEX_HTTP = /(\bhttps?:\/\/[\w!#$&'*+,-./:;=?@~]+)/g
export const REGEX_NUMERIC = /^[0-9]+$/
export const REGEX_NUMERIC_INTEGER = /^[0-9]{1,10}$/

export const renderLink = (text: string) => {
  return text?.replace(REGEX_HTTP, '<a href="$1" target="_blank">$1</a>')
}

export const CheckLink = (text: string) => {
  // console.log(text)
  const textSplit = text?.split(REGEX_HTTP)
  // console.log(textSplit)
  return textSplit?.filter((e) => !['https', 'http', 'ftp', 'file']?.includes(e))
}

export const validURL = (text: string) => {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(text)
}
export const validSimpleUrl = (text: string) => {
  let pattern = new RegExp('^(https?:\\/\\/)', 'i') // http:// or https://
  return pattern.test(text)
}

let HOURS_ARRAY: any = []
for (let i = 0; i < 24; i++) {
  if (i < 10) {
    let x = '0' + String(i)
    HOURS_ARRAY.push(String(x))
  } else {
    HOURS_ARRAY.push(String(i))
  }
}
export const HOURS = HOURS_ARRAY
export const MINUTES = ['00', '30']

/**
 * URLからファイル名を取得する
 * @param url string | null URL文字列
 * @returns string 分解できない文字列が渡された場合は空文字を返却する
 */
export const getFileNameFromUrl = (url: string | null) => {
  if (!url) {
    return ''
  }
  const spritUrl = url.match('.+/([^?#;/]+).*$')
  return spritUrl?.length && spritUrl?.length >= 2 ? spritUrl[1] : ''
}

// Regex to find mentions like @{user_id}
export const mentionRegex = /@\{(\d+)\}/
export const mentionRegexTextInput = /@\{(\d+)\}/g

// Used in text editor, used to detect image element in html sentence, which
// must contains src property as  base64 format
export const BASE64_IMAGE_REGEX = /<img\s+src="data:[^;]+;base64[^"]*">/
export const EDITOR_IGNORE_TEXT = [/<span\s+class="ql-cursor">.*?<\/span>/g, '<p><br></p>', '<blockquote><br></blockquote>']
export const EDITOR_PLACEHOLDER_VISIBLE_REGEX = [
  /^<(?!blockquote)([\w-]+)><br><\/\1>+$/,
  /^<(?!blockquote)([\w-]+)><span class="([\w-]+)">(?:&#xFEFF;|﻿)<\/span><\/\1>+$/,
  /^<(?!blockquote)([\w-]+)><span class="([\w-]+)"><span class="([\w-]+)">(?:&#xFEFF;|﻿)<\/span><\/span><\/\1>+$/,
]
export const EDITOR_NUMBER_OF_LINES_REGEX = /<(p|blockquote|h1|h2|h3|h4|h5|li)\b[^>]*>.*?<\/\1>|[^<]+/gs
// In order to count new line correctly - not conflict with line separate pattern, ul and ol tag need
// to be remove from editor raw content
export const EDITOR_REMOVE_LIST_TAG_REGEX = /<\/?(ul|ol)\b[^>]*>/g
export const EDITOR_TRIM_BOTTOM_REGEX = [
  /<(h[1-5]|li|p|blockquote|div)><br><\/\1>$/,
  /<(h[1-5]|li|p|blockquote|div)><span class="([\w-]+)">&#xFEFF;<\/span><\/\1>$/,
  /<(h[1-5]|li|p|blockquote|div)><code><br><\/code><\/\1>$/,
  /<(h[1-5]|li|p|blockquote|div)><code><span class="([\w-]+)">&#xFEFF;<\/span><\/code><\/\1>$/,
]

export const EDITOR_TRIM_BOTTOM_REGEX_CURSOR = /<(h[1-5]|li|p|blockquote|div)><span class="([\w-]+)">﻿<\/span><\/\1>$/
export const EMPTY_ELEMENT_REGEX = /^<(\w+)><br><\/\1>$/
export const EDITOR_EMPTY_TAG_REGEX = /<(h[1-5]|li|p|blockquote|div)><\/\1>/g
export const EDITOR_EMPTY_CHAR_REGEX = /&#xFEFF;|﻿/g
export const EDITOR_CURSOR_TAG_REGEX = /<span\s+class="ql-cursor">(.*?)<\/span>/g
export const EDITOR_BLOCKQUOTE_DETECT = /<blockquote>(.*?)<\/blockquote>/g
