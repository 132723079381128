import { AUTH_STORE } from '@/store/constants'
import { mutations } from './auth.mutations'
import { getters } from './auth.getters'
import { actions } from './auth.actions'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'

export const state: AuthStateTypes = {
  authenticated: false,
  isAdmin: true,
  notifications: [],
  notificationsCommunity: [],
  notificationFromAdmin: false,
  forgotEmailData: {
    emailCurrent: '',
    email: '',
    secretWord: '',
  },
  numberUnread: {},
  numberUnreadCommunity: {},
  detailNotification: {},
}

const root: Module<AuthStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default root

export type AuthStateTypes = {
  authenticated: boolean
  isAdmin: boolean
  notifications: any
  notificationsCommunity: any
  notificationFromAdmin: boolean
  forgotEmailData: any
  numberUnread: any
  numberUnreadCommunity: any
  detailNotification: any
}

export interface AuthGettersTypes {
  [AUTH_STORE.GETTERS.AUTH_VALUE](state: AuthStateTypes): any
  [AUTH_STORE.GETTERS.IS_ADMIN](state: AuthStateTypes): boolean
  [AUTH_STORE.GETTERS.GET_NOTIFICATIONS](state: AuthStateTypes): any
}

export type AuthMutationTypes<S = AuthStateTypes> = {
  [AUTH_STORE.MUTATIONS.SET_AUTH_VALUE](state: S, payload: any): void
}

export interface AuthActionTypes {
  [AUTH_STORE.ACTIONS.SET_AUTH_VALUE]({ commit }: any, payload: any): void
}

export type AuthModuleType<S = AuthStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof AuthMutationTypes, P extends Parameters<AuthMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<AuthMutationTypes[K]>
} & {
  getters: {
    [K in keyof AuthGettersTypes]: ReturnType<AuthGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof AuthActionTypes>(key: K, payload?: Parameters<AuthActionTypes[K]>[1], options?: DispatchOptions): ReturnType<AuthActionTypes[K]>
}
