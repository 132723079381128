export const jpCommunitySite = {
  CK10ArticleList: 'コミュニティサイト記事一覧',
  CK10PublicStatus: '公開中',
  CK10PrivateStatus: '非公開',
  CK10WriteAnArticle: '作成する',
  CK10PublishStatus: 'ステータス',
  CK10NoSearchData: 'コミュニティサイト記事が見つかりませんでした。',
  CK10NoteDeletePostAutomatic: '※記事（⾃動投稿）を削除しても、コミュニティサイト記事は削除されませんのでご注意ください。',
  CK16DeleteArticle: '記事の削除',
  CK16DeleteArticleConfirmMessage: '記事「%s」を削除します。',
  CK20ArticleCreation: 'コミュニティサイト新規登録',
  CM10ArticleUpdate: 'コミュニティサイト記事編集',
  CM20ArticleUpdateConfirm: 'コミュニティサイト記事編集確認',
  CK20ContentTextLimit: '文字数の上限：%s文字',
  CK20ContentImageLimit: '画像枚数の上限：%s枚',
  CK20ContentImageSizeLimit: '1枚あたりの容量上限：%sMB',
  CK20ContentImageFormat: '画像ファイル形式：.jpg／.jpeg／.png',
  CK20CommunitySiteTag: 'コミュニティサイト記事',
  CK20CurrentNumberOfCharacters: '現在の文字数%d文字',
  CK20AutoPost: '自動投稿',
  CK20AutoPostDescription: '自動投稿は、記事本文の「1つ目の画像」および「冒頭のテキスト100文字」をそのままタイムラインに自動で投稿する機能です。',
  CK20AutoPostYes: 'する',
  CK20AutoPostNo: 'しない',
  CK30CreateArticleConfirmTitle: 'コミュニティサイト新規登録確認',
  CK30CreateArticleSuccessTitle: 'コミュニティサイト記事新規作成',
  CK30CreateArticleSuccessDescription: '登録しました。\n\n' + '画像などの添付ファイルはウィルスチェックに\n時間がかかる場合があります。',
  CM20CreateArticleSuccessDescription: '更新しました。\n\n' + '画像などの添付ファイルはウィルスチェックに\n時間がかかる場合があります。',
  CK30CreateArticleFailedToastMsg: 'コミュニティサイト記事の登録に失敗しました。',
  CK20TagDescription: '文字の入力を確定するとタグとして追加されます。\nタグは1つの記事に5つまで設定できます。',
  CK12TotalNumberOfComment: '他%s件のコメントを見る',
  CK20TooltipBackground: '背景色',
  CK20TooltipBold: '太字',
  CK20TooltipColor: '文字色',
  CK20TooltipFont: 'フォント',
  CK20TooltipInlineCode: 'コード',
  CK20TooltipItalic: '斜体',
  CK20TooltipLink: 'リンクを挿入',
  CK20TooltipSize: 'フォントサイズ',
  CK20TooltipStrike: '取消線',
  CK20TooltipUnderline: '下線',
  CK20TooltipBlockQuote: '引用',
  CK20TooltipHeader1: '見出し1',
  CK20TooltipHeader2: '見出し2',
  CK20TooltipIndentPos1: 'インデント増',
  CK20TooltipIndentNeg1: 'インデント減',
  CK20TooltipListOrdered: '番号付リスト',
  CK20TooltipListBullet: '箇条書き',
  CK20TooltipListTextDirection: 'テキストの方向',
  CK20TooltipListTextAlignment: '配置とインデント',
  CK20TooltipListTextAlignmentLeft: '左揃え',
  CK20TooltipListTextAlignmentRight: '右揃え',
  CK20TooltipListTextAlignmentCenter: '中央揃え',
  CK20TooltipListTextAlignmentJustify: '両端揃え',
  CK20TooltipCodeBlock: 'コードブロック',
  CK20TooltipImage: '画像の挿入',
  CK20TooltipClean: '書式をクリア',
  CK10PublishedDate: '初回公開日時',
}
